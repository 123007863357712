<template>
    <vFormPage klsName="body-cus">
        <div slot="header" class="header">
            <div class="close" @click.prevent="handleClose">×</div>
            <div class="title">ACCOUNT SETTING</div>
        </div>
        <div class="profile-form-body">
            <vForm class="form" ref="form" :model="formInfo" :rules="rules">
                <vFormItem label="User Name" prop="name">
                    <vFormInput v-model.trim="formInfo.name" />
                </vFormItem>
                <vFormItem label="Email" prop="email">
                    <vFormInput v-model.trim="formInfo.email" />
                </vFormItem>
                <vFormItem label="Birthday" prop="birthday">
                    <vFormInput v-model.trim="formInfo.birthday" idV="date-picker-in-pr"/>
                </vFormItem>
                <vFormItem label="Phone" prop="phoneNum">
                    <div class="form-disabled">{{user.phone}}</div>
                </vFormItem>
                <!-- <vFormItem label="Phone" prop="phoneNum">
                    <div class="flex">
                        <vSelect type="green" :options="phoneOptions" v-model="formInfo.phonePrefix"></vSelect>
                        <vFormInput type="number" v-model.number="formInfo.phoneNum" />
                    </div>
                </vFormItem> -->
                <!-- <vFormItem label="Verify Code" prop="phoneCode" class="code-wrapper">
                    <div class="flex">
                        <vFormInput class="code-input" v-model.trim="formInfo.phoneCode" />
                        <vButton :text="codeText" type="primary-o-bold" :code="codeType" :clickBefore="verifyBefore"
                            @btnClick="getCode" :stopTimer="stopTimer" />
                    </div>
                </vFormItem> -->
                <!-- <div class="footer-info">
                    Please enter correct number, we will notify you via text message
                </div> -->
                <div class="btn-wrapper">
                    <vButton :text="is_loading ? 'Saving ...' : 'Save'" @btnClick="save" />
                </div>
            </vForm>
        </div>
    </vFormPage>
</template>
<style scoped>
    /* 顶部样式 */
    .header .title {
        padding-left: 36px;
        padding-top: 35px;
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-align: left;
        color: #fff;
    }

    .header .close {
        position: absolute;
        text-align: -webkit-right;
        color: #CFFFF9;
        font-size: 24px;
        width: 365px;
        padding-top: 35px;
    }

    /* 底部样式 */
    .footer-info {
        text-align: center;
        font-size: 13px;
        color: #AAB0BB;
    }

    .footer-info:nth-child(2) {
        padding-top: 12px;
    }

    .footer-info a {
        color: #0080ff;
    }


    /* 表单样式 */
    .profile-form-body .footer-info {
        font-size: 10px;
        text-align: left;
    }

    .profile-form-body .form {
        margin: 34px 20px 61px 20px;
    }

    .profile-form-body .form .flex {
        display: flex;
    }

    .profile-form-body .form-item {
        font-size: 14px;
    }

    .profile-form-body .form-title-wrapper {
        margin: 39px 31px 34px 31px;
        text-align: center;
        line-height: 18px;
    }

    .profile-form-body .form-title-wrapper .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
    }

    .profile-form-body .form-title-wrapper .title-info {
        font-size: 12px;
        color: #AAB0BB;
    }

    .profile-form-body .code-wrapper {
        margin-bottom: 0;
    }

    .profile-form-body .code-input {
        width: 60%;
    }

    .profile-form-body .form-disabled {
        height: 0.38rem;
        font-size: 0.16rem;
        outline-style: none;
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0.03rem;
        padding: 0 0.04rem;
        color: #000;
        line-height: 0.38rem;
    }

    .btn-wrapper {
        padding-top: 20px;
        display: flex;
        justify-content: center;
    }

    .btn-wrapper .btn {
        width: 166px;
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { getUserName } from '../../kits/tools.js';
    import vFormPage from '../../components/Formpage.vue';
    import vButton from '../../components/Button.vue';
    import vForm from '../../components/form/Form.vue';
    import vFormItem from '../../components/form/FormItem.vue';
    import vFormInput from '../../components/form/FormInput.vue';
    // import vSelect from '../../components/Select.vue';
    //  import vFormItemH from '../../components/FormItemHorizon.vue';

    export default {
        name: 'v-profile-edit',
        components: { vFormPage, vButton, vForm, vFormItem, vFormInput },
        data() {
            return {
                codeText: 'Get Code',
                codeType: true,
                phoneOptions: ['+1', '+86'],
                formInfo: {
                    name: '',
                    email: '',
                    phonePrefix: '+1',
                    phoneNum: '',
                    phoneCode: '',
                    birthday: ''
                },
                rules: {
                    name: [{ required: true, trigger: 'blur' }],
                    email: [{ required: true, trigger: 'blur' }],
                    phonePrefix: [{ required: true, trigger: 'blur' }],
                    phoneNum: [{ required: true, trigger: 'blur' }],
                    phoneCode: [{ required: true, trigger: 'blur' }],
                    birthday: [{ required: true, trigger: 'blur' }],
                },
                stopTimer: false
            }
        },
        created() {
            let { name, email, phone, detail } = this.user, phonePrefix = phone && phone.startsWith('+86') ? '+86' : '+1',
                phoneNum = phone ? phone.substr(phonePrefix.length) : '';
            this.formInfo = {
                name: getUserName(this.user),
                email: email || '',
                phonePrefix,
                phoneNum,
                phoneCode: '',
                birthday: detail && detail.birthday ? detail.birthday : '',
            };
        },
        mounted() {
            this.datePicker(); //加载日期选择控件
        },
        computed: {
            ...mapGetters({
                is_loading: 'app/getLoading',
                sInfo: 'app/getsInfo',
                user: 'app/getUser',
            })
        },
        methods: {
            ...mapActions({
                triggerSms: 'sms/triggerSms',
                verifySms: 'sms/verifySms',
                submitUser: 'app/submitUser',
                checkActive: 'app/checkActive',
            }),
            ...mapMutations({
                setLoading: 'app/APP_SET_IF_LOADING',
                updateUser: 'app/SET_USER',
            }),
            save() {
                this.$refs.form.validate((valid) => {
                    if (!valid || this.is_loading) {
                        return;
                    }

                    this.setLoading({ is_loading: true });
                    let mobile = `${this.formInfo.phonePrefix}${this.formInfo.phoneNum}`;
                    this.submitUser({
                        data: {
                            name: this.formInfo.name,
                            phone: mobile,
                            email: this.formInfo.email,
                            birthday: this.formInfo.birthday
                        },
                        success: (submitRes) => {
                            this.updateUser({ home: true });
                            this.setLoading({ is_loading: false });
                            this.handleClose();
                        },
                        fail: () => {
                            this.setLoading({ is_loading: false });
                        }
                    });
                });
            },
            verifyBefore() {
                return this.formInfo && this.formInfo.phoneNum !== "";
            },
            getCode() {
                this.triggerSms({ data: { mobile: `${this.formInfo.phonePrefix}${this.formInfo.phoneNum}` } });
            },
            handleClose() {
                this.$router.replace('/profile/view');
            },
            datePicker() {
                let that = this;
                var calendar = new datePicker();
                calendar.init({
                    trigger: "#date-picker-in-pr" /*按钮选择器，用于触发弹出插件*/,
                    type: "date" /*模式：date日期；datetime日期时间；time时间；ym年月；year:年*/,
                    minDate: "1900-1-1" /*最小日期*/,
                    maxDate: "2100-12-31" /*最大日期*/,
                    onSubmit: function () {
                        that.formInfo.birthday = calendar.value;
                    },
                    onClose: function () {
                        /*取消时触发事件*/
                    }
                });
            }
        }
    };
</script>